import { FeatureVariableKeys, useFeatureVariableValue } from '@/helpers/feature-provider';

const useGetHomePageInterrupterState = () => {
  const isEnabled = useFeatureVariableValue(FeatureVariableKeys.HomePageInterrupter, false, false);

  const shouldShowInterrupter = isEnabled;

  return {
    shouldShowInterrupter,
  };
};

export default useGetHomePageInterrupterState;
