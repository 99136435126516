// todo can these and should these be configurable for live updates? feature flags etc

/*
* this constant is used for the refetchonmountorargchange config
* This is how long RTKQ will keep your data cached after mount or arg change
* https://redux-toolkit.js.org/rtk-query/api/createApi#refetchonmountorargchange
* value in seconds
 */
export const PERSISTENCE_DEFAULT_REFETCH_ON_ARG_OR_MOUNT_DURATION = 600;

/*
* this constant is used for the keepunuseddatafor config
* This is how long RTKQ will keep your data cached for after the last component unsubscribes
* https://redux-toolkit.js.org/rtk-query/api/createApi#keepunuseddatafor
* in a way this is like a timer when navigating between pages
* components that were once subscribed are no longer subscribed on the new page
* because of this it is important that we dont retrigger a fetch for all data
* for the default value of 60, because it produces a side effect for a page refresh
* value in seconds
 */
export const PERSISTENCE_DEFAULT_REFETCH_UNUSED_DATA_DURATION = 30600;
