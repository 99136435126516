import { DateTime } from 'luxon';
import { FeatureVariableKeys, useFeatureVariableValue } from '@/helpers/feature-provider';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetMembershipsByPersonIdAndDateRangeQuery } from '@/store/queries/v2/student';
import { useGetCBEDataByPersonIdQuery } from '@/store/queries/student';
import { getCurrentCourseMemberships } from '@/myphoenix/utils/course-functions';
import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { getCurrentDate, toDateTime, diffFromToday } from '@/myphoenix/utils/date-time-functions';
import { Attendance, useGetAttendancesHook } from '@/store/hooks/useGetAttendancesHook';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';

const UseGetCbeEngagementTileState = () => {
  const link = 'https://vle.phoenix.edu';
  const errorMessage = 'We’re sorry! We experienced an error retrieving this data. Please refresh your browser.';
  const errorLinkText = 'Reload';
  const toolTipMessage = 'Do one of the following to maintain engagement: Respond within a progress check-in discussion, submit a competency assessment, a competency reflection, or a pacing guide.';

  const personId = getPersonIdFromSessionStorage();

  const blackOutUntil: string = useFeatureVariableValue(FeatureVariableKeys.CbeEngagementTileBlackoutEndDate, 'N/A', false);
  const featureFlagOn = (!blackOutUntil
    || (blackOutUntil !== 'N/A' && diffFromToday(blackOutUntil) <= 0));

  const {
    data: memberships = [],
    isError: membershipsError,
    isLoading: membershipsLoading,
  } = useGetMembershipsByPersonIdAndDateRangeQuery(
    { personId },
    { skip: !featureFlagOn || !personId },
  ) as {
    data: StudentCourseMembership[],
    isLoading: boolean,
    isError: boolean,
  };

  const {
    data: { primaryProgram = {} },
    isError: programError,
    isFetching: programLoading,
  } = useGetProgramsByIdHook(personId, !featureFlagOn);

  const programCode = primaryProgram?.code;
  const programVersion = primaryProgram?.version;

  const currentCourseMemberships = getCurrentCourseMemberships(memberships);
  const currentCBECourseMemberships = currentCourseMemberships.filter(
    (membership: { offeringType?: string, statusSubCode?: string }) => membership.offeringType === 'CB' && (membership.statusSubCode !== 'TA' && membership.statusSubCode !== 'WI'
    && membership.statusSubCode !== 'WL' && membership.statusSubCode !== 'WP' && membership.statusSubCode !== 'DR'),
  );

  const { isError: attendanceError,
    isFetching: attendanceLoading,
    data: { attendances } } = useGetAttendancesHook(currentCBECourseMemberships);
  const { isError: cbeError,
    isLoading: cbeLoading,
    data: { lastARADate: lastPost, estimatedDropDate } = {} } = useGetCBEDataByPersonIdQuery(
    { personId, programCode, programVersion },
    { skip: !featureFlagOn || !personId || !programCode || !programVersion },
  );
  const isCBE = primaryProgram?.cbeProgram === 'TRUE';
  const today = getCurrentDate().endOf('day');
  const formattedDropDate = toDateTime(estimatedDropDate).minus({ days: 1 })
    .toLocaleString(DateTime.DATE_FULL);
  const postedToday: boolean = toDateTime(lastPost).hasSame(today, 'day');
  const daysLeft = Math.ceil(toDateTime(estimatedDropDate).diff(today, 'days').as('days'));
  const startDate = toDateTime(currentCBECourseMemberships[0]?.startDate);
  const isFirstWeek = today.diff(startDate, 'days').days < 7;
  const daysSinceLastPost = today.diff(toDateTime(lastPost).endOf('day'), 'days').days;
  const isInDangerZone = (daysLeft < 4 && !postedToday);

  const shouldShowEngagementTile = () => {
    if (!featureFlagOn) {
      return false;
    }
    // Lists the attendance's membershipIds to cross-check with existing
    // CBE course's membershipIds and then saves into 'existingAtt'
    // which will be used to display (or not) the engagementCBE container
    const existingAtt: string[] = [];
    // For each CBE Course, check if there is attendance data
    // then, extract that data, iterate through and save it to existingAtt[]
    let latestEndDate = '';
    if (currentCBECourseMemberships.length > 0) {
      latestEndDate = currentCBECourseMemberships[0].endDate;
      currentCBECourseMemberships.forEach((membership) => {
        attendances?.byMembershipId[membership?.id]?.attendances?.forEach(
          (att: Attendance) => {
            existingAtt.push(att.attendance);
          },
        );
        if (toDateTime(membership.endDate) > toDateTime(latestEndDate)) {
          latestEndDate = membership.endDate;
        }
      });
    }
    return isCBE && ((existingAtt.length > 0)
      && (existingAtt.some((value) => value === 'Y' || value === 'E')
      && existingAtt.every((value) => value === 'Y' || value === 'N' || value === 'E')))
      && (toDateTime(estimatedDropDate) < toDateTime(latestEndDate));
  };

  const showEngagementTile = shouldShowEngagementTile();
  return {
    link,
    errorMessage,
    errorLinkText,
    toolTipMessage,
    postedToday,
    daysLeft,
    formattedDropDate,
    isInDangerZone,
    isFirstWeek,
    daysSinceLastPost,
    showEngagementTile,
    isError: membershipsError
      || programError
      || cbeError
      || attendanceError,
    isFetching: membershipsLoading
      || programLoading
      || cbeLoading
      || attendanceLoading,
  };
};

export default UseGetCbeEngagementTileState;
