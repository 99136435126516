import { Features } from '@/helpers/feature-provider';
import getLinkProperties from '@/helpers/get-link-properties';
import {
  COURSE_INFO_START_MARK_NAME,
  COURSE_INFO_RENDERED_MARK_NAME,
  COURSE_INFO_MEASURE_NAME,
  publishMyPhoenixMeasureEvent,
} from '@/myphoenix/utils/event-functions';

// Determines if current url contains a string
const fetchLocation = (value: string) => window?.location?.href?.indexOf(value) !== -1;

const navigate = (url: string, target: string = undefined) => {
  const { url: linkUrl, target: linkTarget } = getLinkProperties(url);
  if ((target ?? linkTarget) === '_self') window.location.assign(linkUrl);
  else window.open(linkUrl);
};

/* Usage
async function functionName() {
  await waitFor(1000);
 ...
} */
// eslint-disable-next-line no-promise-executor-return
const waitFor = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const hasQueryParam = (key: string) => {
  if (typeof window !== 'undefined') {
    return window?.location?.search?.includes(key);
  }
  return false;
};

let scenarioEntriesLength = 0;
const addPerformanceMarkObserver = () => {
  try {
    const { performanceMarksEnabled } = Features;
    if (performanceMarksEnabled && typeof window !== 'undefined') {
      const markObserver = new PerformanceObserver(() => {
        // course info rendering marks
        const startEntries = window.performance?.getEntriesByName(
          COURSE_INFO_START_MARK_NAME,
        );
        const scenarioEntries = window.performance?.getEntriesByName(
          COURSE_INFO_RENDERED_MARK_NAME,
        );
        if (startEntries.length && scenarioEntries.length !== scenarioEntriesLength) {
          scenarioEntriesLength = scenarioEntries.length;
          const lastScenarioEntry = scenarioEntries[scenarioEntriesLength - 1] as PerformanceMark;
          const duration = lastScenarioEntry.startTime - startEntries[0].startTime;
          if (duration < 60000) {
            // only include course info renders within a minute so as to exclude any re-renders
            // as a result of any reacty/reduxy late reloads within the same page request
            publishMyPhoenixMeasureEvent(COURSE_INFO_MEASURE_NAME, {
              start: startEntries[0].startTime,
              end: lastScenarioEntry.startTime,
              value: duration,
              detail: lastScenarioEntry.detail,
            });
          }
        }
      });
      markObserver.observe({ type: 'mark', buffered: true });
    }
  } catch {
    // do nothing
  }
};

const addPerformanceMark = (markName: string, detail?: string | number) => {
  try {
    const { performanceMarksEnabled } = Features;
    if (performanceMarksEnabled && typeof window !== 'undefined') {
      window.performance?.mark(markName, { detail });
      return true;
    }
  } catch {
    // do nothing
  }
  return false;
};

export {
  fetchLocation,
  navigate,
  waitFor,
  addPerformanceMarkObserver,
  addPerformanceMark,
};
