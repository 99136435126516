import { Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { FeatureVariableKeys, useFeatureVariableValue } from '@/helpers/feature-provider';
import { useDisplayEventTracking } from '@/hooks/useDisplayEventTracking';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { getItem, setItem } from '@/helpers/session-storage';
import Icon from '@/components/atoms/icon';
import ArrowLink from '@/components/atoms/arrow-link';
import { useGetAudiencesQuery, useGetComputedTraitsQuery } from '@/store/queries/segment';
import { getBBClassUrl, getBBRootUrl } from '@/myphoenix/utils/blackboard-functions';
import useGetMembershipsAndCourses from '@/hooks/useGetMembershipsAndCourses';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { sortMemberships } from '@/helpers/filter-courses';
import { useGetActivitiesBySectionIdQuery } from '@/store/queries/v2/activities';
import {
  StyledBox,
  StyledIcon,
  AlertGrid,
  StyledHeading,
  StyledIconButton,
  MessageGridItem,
  MessageTypography,
  ArrowLinkGridItem,
} from './InlineAlert.styles';

type DeviceType = 'mobile' | 'tablet' | 'desktop';

type SeverityType = 'error' | 'warning' | 'info' | 'success' | null;

type Alert = {
  headingMessage?: string,
  message?: string,
  severity?: SeverityType,
  link?: string,
  linkLabel?: string,
  isDismissable?: boolean,
  deviceType?: DeviceType,
  audience?: string,
};

type AudienceDeviceAlert = {
  audience: string,
  mobile: Alert,
  tablet: Alert,
  desktop: Alert,
};

const getIcon = (severity: SeverityType) => {
  switch (severity) {
    case 'warning':
      return 'icon-alert-sharp';
    case 'success':
      return 'icon-green-circle-check';
    default:
      return 'icon-info-solid';
  }
};

const getColor = (severity: SeverityType, theme: Theme) => {
  switch (severity) {
    case 'error':
      return theme.palette.primary.d20;
    case 'warning':
      return theme.palette.custom.warnOrange;
    default:
      return theme.palette.custom.infoBlue;
  }
};

const getAlertByDevice = (
  deviceType: DeviceType,
  audienceDeviceAlert?: AudienceDeviceAlert,
): Alert => {
  let alert: Alert = null;
  if (audienceDeviceAlert) {
    if (deviceType === 'mobile') {
      alert = audienceDeviceAlert.mobile;
    } else if (deviceType === 'tablet') {
      alert = audienceDeviceAlert.tablet;
    } else {
      alert = audienceDeviceAlert.desktop;
    }
    alert.deviceType = deviceType;
    alert.audience = audienceDeviceAlert.audience;
  }
  return alert || {
  };
};

const getAlertByAudienceByDevice = (
  audiences?: string[],
  deviceType?: DeviceType,
  audienceDeviceAlerts?: AudienceDeviceAlert[],
): Alert | null => {
  if (audiences && deviceType && audienceDeviceAlerts) {
    const matchingAudienceDeviceAlert: AudienceDeviceAlert = audienceDeviceAlerts.find(
      (audienceDeviceAlert) => (
        audiences.includes(audienceDeviceAlert.audience)
        && (getAlertByDevice(deviceType, audienceDeviceAlert).message)
      ),
    );
    return getAlertByDevice(deviceType, matchingAudienceDeviceAlert);
  }
  return null;
};

const getDeviceType = (
  isMobile: boolean,
  isTablet: boolean,
) => {
  if (isMobile) {
    return 'mobile';
  } if (isTablet) {
    return 'tablet';
  }
  return 'desktop';
};

function lateAssignmentAudience() :Alert {
  return {
    headingMessage: "There's still time to earn valuable points",
    message: "Complete your assignment now to stay on track. Reach out for help if you need it, we're here for you.",
    severity: 'info',
    isDismissable: true,
    link: '#',
    linkLabel: 'Go to class',
  };
}

const registeredAndNotFirstCourseCompletedAudience: Alert = {
  headingMessage: 'Time to get started',
  message: 'Now that you are registered, it is time to get started on your first course. Reach out for help if you need it, we are here for you.',
  severity: 'info',
  isDismissable: true,
};

function InlineAlertBox({ audiences, audienceDeviceAlerts =

[{
  audience: 'tech_nudge_mobile',
  mobile: {
    headingMessage: 'Try using your computer',
    message: 'Students who do their assignments on their computers are twice as likely to succeed in class.',
    severity: 'info',
    isDismissable: true,
  },
  tablet: {
  },
  desktop: {
  },
},
{
  audience: 'has_late_assignment_worth_points',
  mobile: lateAssignmentAudience(),
  tablet: lateAssignmentAudience(),
  desktop: lateAssignmentAudience(),
}, {
  audience: 'registered_and_not_first_course_completed',
  mobile: registeredAndNotFirstCourseCompletedAudience,
  tablet: registeredAndNotFirstCourseCompletedAudience,
  desktop: registeredAndNotFirstCourseCompletedAudience,
}] }: { audiences?: string[], audienceDeviceAlerts?: AudienceDeviceAlert[] }) {
  const componentName = 'InlineAlertBox';
  const trackDisplay = useDisplayEventTracking();
  const trackClick = useClickEventTracking();
  const [dismissToggle, setDismissToggle] = useState(false);
  const DISMISSED_SESSION_ID_PREFIX = 'myphoenix-inlinealert-dismissed';
  const wasAlreadyDismissed = (audience: string) => !!getItem(`${DISMISSED_SESSION_ID_PREFIX}-${audience}`);
  const dismiss = (audience:string) => {
    setDismissToggle(!dismissToggle);
    setItem(`${DISMISSED_SESSION_ID_PREFIX}-${audience}`, true);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const deviceType = getDeviceType(isMobile, isTablet);
  const personId = getPersonIdFromSessionStorage();

  const pcRegisteredNot1stCourseCompletedValue = useFeatureVariableValue<boolean>(
    FeatureVariableKeys.ProspectConversionsRegisteredAndNotFirstCourseCompleted,
    false,
  );

  const {
    data: audiencesFromSegment = [],
    isLoading: audiencesLoading,
  } = useGetAudiencesQuery({ personId }, { skip: Array.isArray(audiences) });

  const alertAudiences = audiences || (!audiencesLoading && audiencesFromSegment);
  const alert = getAlertByAudienceByDevice(alertAudiences, deviceType, audienceDeviceAlerts);

  const {
    memberships,
    courses,
  } = useGetMembershipsAndCourses(personId, alert?.audience !== 'has_late_assignment_worth_points');

  const notDroppedMemberships = memberships.filter((membership) => membership.statusSubCode !== 'DR' && membership.statusSubCode !== 'TA');
  const sortedMembershipIds = sortMemberships(notDroppedMemberships);
  // by default, this returns sourceIds, not membershipIds
  const currentCourse = sortedMembershipIds.currentMembershipIds
    .map((sourceId) => courses
      .find((course: { id: string }) => {
        if (course.id === sourceId) {
          return sourceId;
        } return false;
      }))[0];

  const {
    data: computedTraits = [],
  } = useGetComputedTraitsQuery(
    { personId },
    { skip: alert?.audience !== 'has_late_assignment_worth_points' },
  );
  const sectionId = computedTraits?.last_late_assignment_course_offering_id;

  const {
    data: activities,
  } = useGetActivitiesBySectionIdQuery(
    { sectionId },
    { skip: !sectionId },
  );
  const lateAssignmentObj = activities?.activities.find((lateAssignmentSourceId) => lateAssignmentSourceId.id.replace(/_/g, '').slice(0, -1) === computedTraits?.last_late_assignment_source_id);
  useEffect(() => {
    if (alert?.message && !wasAlreadyDismissed(alert.audience)) {
      trackDisplay(
        componentName,
        [
          { audienceName: alert.audience },
          { label: alert.audience },
          { device: alert.deviceType },
        ],
      );
    }
  }, [trackDisplay, alert?.audience, alert?.deviceType, alert?.message]);

  if (!(alert && alert.message) || wasAlreadyDismissed(alert.audience)) {
    return null;
  }

  // If the user is registered and has not completed their first course,
  // and the feature flag is off, don't show the alert
  if (alert.audience === 'registered_and_not_first_course_completed' && !pcRegisteredNot1stCourseCompletedValue) {
    return null;
  }

  const icon = getIcon(alert.severity);
  const severityColor = getColor(alert.severity, theme);

  const onCloseClick = () => {
    dismiss(alert.audience);
    trackClick(
      `${componentName}_Close`,
      [
        { audienceName: alert.audience },
        { label: alert.audience },
        { device: alert.deviceType },
      ],
    );
  };

  const getAlertLink = () => {
    let alertLink;
    if (alert?.audience === 'has_late_assignment_worth_points') {
      if (lateAssignmentObj?.url) {
        alertLink = lateAssignmentObj?.url;
      } else if (currentCourse?.templateCode) {
        alertLink = getBBClassUrl(currentCourse?.templateCode);
      } else {
        alertLink = getBBRootUrl();
      }
    }

    // Due to time constraints we didnt seperate
    // the data from the UI. Whole thing is pending a refactor

    return alertLink;
  };

  return (
    <StyledBox
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      $severity={severityColor}
    >
      <StyledIcon
        id={`${alert.severity}-icon`}
        icon={icon}
        color={severityColor}
      />
      <AlertGrid
        container
        direction="column"
        spacing={0}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid item>
              <StyledHeading variant="body4">
                {alert.headingMessage}
              </StyledHeading>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            wrap="nowrap"
            spacing={0}
          >
            <MessageGridItem item $isDismissable={alert.isDismissable}>
              <MessageTypography variant="body5">
                {alert.message}
              </MessageTypography>
            </MessageGridItem>
            {alert.link
              && ((alert.severity === 'info' || alert.severity === 'success') ? (
                <ArrowLinkGridItem item>
                  <ArrowLink
                    link={getAlertLink()}
                    cta={false}
                    clickEvent={{
                      componentName,
                      properties: [
                        { audienceName: alert.audience },
                        { label: alert.audience },
                        { device: alert.deviceType },
                        { link: getAlertLink() },
                        { text: alert.linkLabel },
                      ],
                    }}
                  >
                    {alert.linkLabel}
                  </ArrowLink>
                </ArrowLinkGridItem>
              ) : (
                <ArrowLinkGridItem item>
                  <ArrowLink
                    link={getAlertLink()}
                    clickEvent={{
                      componentName,
                      properties: [
                        { audienceName: alert.audience },
                        { label: alert.audience },
                        { device: alert.deviceType },
                        { link: getAlertLink() },
                        { text: alert.linkLabel },
                      ],
                    }}
                  >
                    {alert.linkLabel}
                  </ArrowLink>
                </ArrowLinkGridItem>
              ))}
          </Grid>
        </Grid>
      </AlertGrid>
      {alert.isDismissable && (
        <StyledIconButton
          onClick={onCloseClick}
          aria-label="Close this alert"
        >
          <Icon
            id="icon-close"
            icon="icon-close"
          />
        </StyledIconButton>
      )}
    </StyledBox>
  );
}

export default InlineAlertBox;
